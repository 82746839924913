import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { UserService } from "src/app/services/user.service";
import * as userActions from "../actions/user.actions";

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserService) {}

  updateOnCallStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.updateOnCallStatus),
      switchMap((data) =>
        this.userService.updateOnCallStatus(data).pipe(
          map((data) => ({
            type: "[Users API] Update OnCall Status Success",
            payload: data,
          })),
          catchError((error) => EMPTY)
        )
      )
    );
  });
}
